function cloneKendoDataSource(grid) {
    return new kendo.data.DataSource({
        transport: {
            read: {
                url: grid.dataSource.options.transport.read.url,
                contentType: "application/json",
                type: "POST",
                data: {
                    page: 1,
                    pageSize: grid.dataSource.total(),
                    selected: grid.selectedKeyNames(),
                    filter: grid.dataSource.filter(),
                    skip: 0,
                    take: grid.dataSource.total()
                }
            },
            parameterMap: function (options) {
                return kendo.stringify(options);
            }
        },
        schema: {
            data: "data",
            total: "total",
            model: {
                id: "id"
            }
        }
    });
}

function readFromClonedKendoDataSource(grid, callback) {
    window.GeneralUtilities.loading(true);
    const dataSource = cloneKendoDataSource(grid);
    dataSource.read().then(() => {
        window.GeneralUtilities.loading(false);
        if (callback) {
            const allItems = dataSource.view();
            const selectedIds = dataSource.options.transport.read.data.selected;
            const selectedItems = [];
            selectedIds.forEach(function (number) {
                allItems.forEach(item => {
                    if (number === item.id) {
                        selectedItems.push(item)
                    }
                })
            });
            callback(selectedItems, selectedIds);
        };
    });
}

function setColumnTemplate(column, template) {
    const modifiedColumn = structuredClone(column)
    modifiedColumn.template = template;

    return modifiedColumn;
}

//TODO: Should be moved outside of grid-utils, since is used in Actionbar.js which doesn't use kendogrid and doesn't include <partial name="_KendoResources" />
function debounce(fn, wait) {
    let timer;
    return function (...args) {
        if (timer) {
            clearTimeout(timer); // clear any pre-existing timer
        }
        const context = this; // get the current context
        timer = setTimeout(() => {
            fn.apply(context, args); // call the function if time expires
        }, wait);
    }
}

function getSubMemberName(memberPath){

    // If the member path is using the pattern object.member (seperated by a dot), simply return the the member 
    if (memberPath.indexOf(".") > -1){
        return memberPath.split(".")[1];
    }

    // Extract a sub property name based on a input string => object["member"] where "member" is returned
    const matches = memberPath.match(/\["([^"]+)"\]/);

    if (matches.length > 0)
        return matches[1];

    return undefined;
}

async function encodeAndCompressToHash(objectToHash) {
    const stringToHash = JSON.stringify(objectToHash);
    const stream = new Blob([stringToHash], { type: "application/json" }).stream();
    const compressedReadableStream = stream.pipeThrough(new CompressionStream("gzip"));
    const compressedResponse = new Response(compressedReadableStream);
    const blob = await compressedResponse.blob();
    const buffer = await blob.arrayBuffer();
    const compressedData = btoa(String.fromCharCode(...new Uint8Array(buffer)));

    return encodeURIComponent(compressedData);
}

async function decodeAndDecompressFromHash(encodedHash) {
    const stream = new Blob([b64decode(decodeURIComponent(encodedHash))], { type: "application/json" }).stream();
    const compressedReadableStream = stream.pipeThrough(new DecompressionStream("gzip"));
    const resp = new Response(compressedReadableStream);
    const blob = await resp.blob();
    return JSON.parse(await blob.text());
}

function b64decode(str) {
    const binary_string = atob(str);
    const len = binary_string.length;
    const bytes = new Uint8Array(new ArrayBuffer(len));
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
}

export { cloneKendoDataSource, readFromClonedKendoDataSource, setColumnTemplate, debounce, getSubMemberName, encodeAndCompressToHash, decodeAndDecompressFromHash };
