import Vue from 'vue'
import 'vue2-datepicker/index.css'
import axios from "axios"

module.export = Vue.component('assetremovalmodal',
    {
        name: "assetremovalmodal",
        props: {
            selectedAssets: Array,
            assetCount: Number,
            assetsToBeRemovedCount: Number,
            assetsBackOfficeCount: Number,
            assetsBackOfficeIds: Array,
            assetsShouldBeExcluded: Boolean,
            assetFilter: Object
        },
        data: function () {
            return {
                alerts: []
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function () {
                this.clearAlert();
                let vueObject = this;
                $("#saveButton").prop("disabled", true);
                if (!this.assetsShouldBeExcluded) {
                    const selectedAssets = vueObject.selectedAssets.map((a) => ({
                        id: a.id,
                        contractId: a.contractId,
                        status: a.assetStatusDll,
                        isBackofficeAsset: a.assetReferences != null
                    })).filter(a => !a.isBackofficeAsset);
                    this.removeAssets(selectedAssets).then((response) => {
                        if (!response.success) {
                            vueObject.addAlert({
                                "message": response.message,
                                "isSuccess": false
                            });
                        } else {
                            this.close();
                            this.$emit('alert', { "message": 'Asset(s) successfully removed', "isSuccess": true, "showPopup": true });
                        }
                    },
                    (error) => {
                        vueObject.addAlert({
                            "message": error.message,
                            "isSuccess": false
                        });
                    });
                } else {
                    this.fetchPageRemovalData().then((response) => {
                        this.removeAssetsFromResponseRecursively(vueObject, response);
                    },
                    (error) => {
                        vueObject.ShowError = true;
                        vueObject.addAlert({
                            "message": error.message,
                            "isSuccess": false
                        });
                    });
                }
            },

            removeAssets: function (selectedAssets) {
                let selectedContractIds = [...new Set(selectedAssets.map(i => i.contractId))];
                let selectedAssetIds = selectedAssets.map(i => i.id);
                return new Promise((resolve, reject) => {
                    $.ajax({
                        url: "/LeasedAssets/Remove",
                        data: JSON.stringify({ contracts: selectedContractIds, assetIds: selectedAssetIds }),
                        type: "POST",
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            fetchPageRemovalData: function (pageToken) {
                return new Promise((resolve, reject) => { 
                    $.ajax({
                        type: 'POST',
                        url: '/LeasedAssets/QueryTerminationRemovalData',
                        data: JSON.stringify(({ 
                            token: pageToken,
                            limit: 250,
                            filter: this.assetFilter
                        })),
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            removeAssetsFromResponseRecursively: function (vueObject, response) {
                if (response) {
                    const token = response.pageToken;
                    const selectedAssets = response.items.filter(a => !this.selectedAssets.some(a2 => a2.id === a.id)).map((a) => ({
                        id: a.id,
                        contractId: a.contractId,
                        status: a.status,
                        isBackofficeAsset: a.isBackofficeAsset
                    })).filter(a => !a.isBackofficeAsset);
                    
                    this.removeAssets(selectedAssets).then((removalResponse) => {
                        if (!removalResponse.success) {
                            vueObject.addAlert(
                                {
                                    "message": `Could not remove asset: ${removalResponse.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                        if (token) {
                            this.fetchPageRemovalData(token).then((response) => {
                                this.removeAssetsFromResponseRecursively(vueObject, response);
                            },
                            (error) => {
                                vueObject.addAlert(
                                    {
                                        "message": 'Could not remove asset due to a server error',
                                        "isSuccess": false
                                    });
                                $("#saveButton").removeAttr("disabled");
                            });      
                        } else {
                            this.close();
                            this.$emit('alert', { "message": 'Asset(s) successfully removed', "isSuccess": true, "showPopup": true });
                        }   
                    },
                    (error) => {
                        vueObject.addAlert(
                            {
                                "message": 'Could not remove asset due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    });
                }
            },
            download: function () {
                axios.get('/LeasedAssets/GetBackOfficeAssetsExcelFile?backOfficeAssetsIds=' + this.assetsBackOfficeIds.join(','), { responseType: 'blob' })
                    .then(response => {
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(response.data);
                        link.download = 'Back-Office-Assets-To-Be-Removed_' + new Date().toISOString() + '.xlsx';
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }).catch(console.error);
            },
            finish: function () {
                this.$emit('refresh');
                this.close();
            },
            close: function () {
                this.clearAlert();
                $('#asset-removal-modal').modal('hide');
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            show: function() {
                $('#asset-removal-modal').modal('show');
            }
        }
    });
