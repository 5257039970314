import Vue from 'vue'

module.export = Vue.component('managepreviewsmodal',
    {
        name: "managepreviewsmodal",
        props: {
            gridPreviews: Array,
            grid: Object
        },
        data: function () {
            return {
                alerts: [],
                previews: this.gridPreviews,
                previewsToAdd: [],
                previewToDelete: null,
                gridData: this.grid,
                gridType: "",
                previewName: "",
                columns: [
                    { 'field': 'name', 'title': 'Preview name', 'filterable': false },
                    {
                        'field': 'actions',
                        'title': ' ',
                        'filterable': false,
                        'template': '<div style="text-align:right;">' + [
                            '<a class="anchor-remove" href="\\#""><span class="icon-rowaction icon-rowaction--dark icon-rowaction--trash-can" title="Remove grid preview"></span></a>'
                        ].join('') + '</div>',
                        'width': '120px'
                    }
                ]
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function (e) {
                e.preventDefault();
                this.previewToDelete ? this.removeGridPreview(this.previewToDelete) : this.addGridPreview();
            },
            addGridPreview: function () {
                const _this = this;
                const name = _this.previewName.trim();
                if (name !== "") {
                    if (_this.previews.some(preview => preview.name === name)) {
                        _this.addAlert(
                            {
                                "message": 'A preview with this name already exists',
                                "isSuccess": false
                            });

                        return;
                    }

                    const activeFilters = _this.gridData.getActiveFilters();
                    activeFilters.filters = activeFilters.filters?.filter(activeFilter => !activeFilter.filters);
                    const activeSorting = _this.gridData.getActiveSorting();
 
                    const newPreview = {
                        name: name,
                        gridType: _this.gridType,
                        activeFilters: activeFilters,
                        activeSorting: activeSorting,
                        selectedColumns: _this.gridData.config.SelectedColumns
                    };

                    $.ajax({
                        type: 'POST',
                        url: '/GridPreviews/Add',
                        data: JSON.stringify(newPreview),
                        success: function (data) {
                            if (data.success) {
                                _this.previewName = "";
                                _this.$emit('grid-preview-changed', "Added new preview successfully", "ADDED");
                                $('#manage-previews-modal').modal('hide');
                                _this.clearAlert();
                            }
                            else {
                                _this.addAlert(
                                    {
                                        "message": `Could not add grid preview: ${data.data}`,
                                        "isSuccess": false
                                    });
                            }
                        },
                        error: function (data) {
                            _this.addAlert(
                                {
                                    "message": 'Could not add asset due to a server error',
                                    "isSuccess": false
                                });
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                } else {
                    _this.addAlert(
                        {
                            "message": 'Could not add grid preview due to missing preview name',
                            "isSuccess": false
                        });
                }
            },
            removeGridPreview: function (gridPreview) {
                const _this = this;
                $.ajax({
                    type: 'POST',
                    url: '/GridPreviews/Remove',
                    data: JSON.stringify({
                        id: gridPreview.id
                    }),
                    success: function (data) {
                        if (data.success) {
                            _this.$emit('grid-preview-changed', "Removed preview successfully", "DELETED");
                            $('#manage-previews-modal').modal('hide');
                            _this.previews = _this.previews.filter(preview => preview.Id !== gridPreview.Id);
                            _this.previewName = "";
                            _this.previewToDelete = null;
                            _this.clearAlert();
                        }
                        else {
                            _this.addAlert(
                                {
                                    "message": `Could not remove grid preview: ${data.data}`,
                                    "isSuccess": false
                                });
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not remove grid preview due to a server error',
                                "isSuccess": false
                            });
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            show: function (previews, gridType) {
                this.previews = previews;
                this.gridType = gridType;
                $('#manage-previews-modal').modal('show');
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                $('#manage-previews-modal').modal('hide');
            },
            back: function () {
                this.previewToDelete = null;
            },
            gridOnDatabound: function (e) {
                const _this = this;
                const grid = this.$refs.managepreviewsgrid.kendoWidget();
                grid.element.on('click', '.anchor-remove',
                    function (e) {
                        e.preventDefault();
                        _this.previewToDelete = grid.dataItem($(this).closest("tr[data-uid"));
                    });
            }
        }
    });