import Vue from 'vue'
import VeeValidate from "vee-validate"
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import "../Grid/Grid.less"
import axios from "axios"

if (document.getElementById('vue-upload-status-overview')) {
    const actionbar = require("../Actionbar/Actionbar.js");
    const alerts = require("../Alerts/Alerts.js");
    
    Vue.use(GridInstaller);
    Vue.use(VeeValidate);

    const pagesizesession = "pagesize";
    let pagesize = sessionStorage.getItem(pagesizesession);
    if (!pagesize) {
        pagesize = 20;
    }
    new Vue({
        el: '#vue-upload-status-overview',
        components: {},
        data: function () {
            return {
                GridConfiguration: window.preLoadedData.GridConfiguration,
                dataSource: {
                    data: [],
                    transport: {
                        read: {
                            url: "/UploadStatus/List", 
                            contentType: "application/json",
                            type: "POST"
                        },
                        parameterMap: function (options) {
                            return kendo.stringify(options);
                        }
                    },
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    pageSize: pagesize,
                    schema: {
                        data: "data",
                        total: "total"
                    }
                },
                pageable: {
                    buttonCount: 3,
                    info: true,
                    type: 'numeric',
                    pageSizes: [10, 20, 30, 50],
                    previousNext: true
                },
                columns: {},
                actionbar: window.preLoadedData.Actionbar,

                search: {
                    visible: true
                },
                selectedAssets: [],
                alerts: []
            };
        },
        created: function () {
            this.loadColumns();

            this.actionbar.searchVisible = true;
        },
        mounted: function () {
        },
        methods: {
            addAlert: function (event) {
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            loadColumns: function (e) {
                this.columns = [
                    { 'field': 'uploadedon', 'title': 'Uploaded on', 'filterable': false, 'width': '10%' },
                    { 'field': 'filename', 'title': 'File name', 'filterable': false, 'width': '20%' },
                    { 'field': 'assetsprocessed', 'title': 'Assets processed', 'filterable': false, 'width': '15%' },
                    { 'field': 'assetsfailed', 'title': 'Assets Failed', 'filterable': false, 'width': '15%' },
                    { 'field': 'status', 'title': 'Status', 'filterable': false, 'template': "# if (status=='success') { # <span class='icon-rowaction icon-rowaction--dark icon-rowaction--checkmark--filled' title='Success'></span> # } else { # <span class='icon-rowaction icon-rowaction--dark icon-rowaction--misuse' title='Failed'></span> # } #" , 'width': '15%' }
                ]

                let rowactions = '';

                rowactions += "# if (status=='failed') { # <a class='anchor-download' href''><span class='icon-rowaction icon-rowaction--dark icon-rowaction--download' title='Download'></span></a> # } #";
                rowactions += "# if (status=='failed') { # <a class='anchor-resolve' href''><span class='icon-rowaction icon-rowaction--dark icon-rowaction--checkmark' title='Resolve'></span></a> # } #";
                if (rowactions != '') {
                    this.columns.push({ 'title': ' ', 'filterable': false, 'template': rowactions, 'width': '5%' });
                }
            },
            addTooltip: function addTooltipToTableHeaders(grid) {
                grid.element[0].querySelectorAll(".k-header").forEach(element => {
                    const title = $(element).data("title");

                    if (!/<[a-z\][\s\S]*>/i.test(title)) {

                        $(element).attr('title', title);
                    }
                });
            },
            actionbarSearch: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                let filter = {};
                if (e !== null && e !== '') {
                    filter = { logic: 'or', filters: [] };
                    grid.columns.forEach(function (x) {
                        if (x.field) {
                            filter.filters.push({
                                field: x.field,
                                operator: 'contains',
                                value: e.trim()
                            });
                        }
                    });
                }   
                grid.dataSource.filter(filter);
            },
            save: function (event) {
                this.alert = "";
            },
            gridOnDatabound: function (e) {
                const _this = this;
                const grid = this.$refs.grid.kendoWidget();
                grid.element.unbind('dblclick');

                grid.element.on('dblclick', 'tbody tr[data-uid]',
                    function (e) {
                        e.preventDefault();
                        const upload = grid.dataItem($(this).closest("tr[data-uid"));
                        if (upload.uploadid !== null) {
                            window.open("/UploadStatus/DownloadCorrectedFile?uploadId=" + upload.uploadid, "_blank");
                        }
                    });
                grid.element.on('click', '.anchor-download',
                    function (e) {
                        e.preventDefault();
                        const upload = grid.dataItem($(this).closest("tr[data-uid"));
                        window.open("/UploadStatus/DownloadCorrectedFile?uploadId=" + upload.uploadid, "_blank");
                    });
                grid.element.on('click', '.anchor-resolve',
                    function (e) {
                        e.preventDefault();
                        const upload = grid.dataItem($(this).closest("tr[data-uid"));
                        axios.post("/UploadStatus/Resolve?notificationId=" + upload.notificationId)
                            .then((response) => {
                                if (response.data.success) {
                                    location.reload();
                                } else {
                                    _this.addAlert(
                                        {
                                            "message": `Could not resolve upload: ${response.data.data}`,
                                            "isSuccess": false
                                        });
                                }
                            }).catch(error => {
                                _this.addAlert(
                                    {
                                        "message": 'Could not resolve upload due to a server error',
                                        "isSuccess": false
                                    });
                            });
                    });

                this.addTooltip(grid);
            }
        }
    });
    $('.k-header').dblclick(function (e) {
        if (e.offsetY > $(this).outerHeight() - 2) {
            this.width = getTextWidth(this.textContent, this.font);
            //get column number
            const colNr = this.getAttribute("data-index");
            //select column
            //add search in class k-grid-content
            const gridCols = document.getElementById("upload-status-overview-grid").getElementsByClassName("k-grid-content")[0].querySelector("colgroup").childNodes;
            gridCols[colNr].width = $(this).outerWidth();
        }
    })

    function getTextWidth(text, font) {
        // re-use canvas object for better performance
        const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");
        context.font = font;
        const metrics = context.measureText(text);
        //add space for the filter and arroww
        const tableIcons = 80;
        const total = metrics.width + tableIcons;
        return total;
    }
    const pageSizeDropDownList = document.getElementsByClassName('k-pager-sizes')[0].querySelector("[data-role=dropdownlist]");
    pageSizeDropDownList.onchange = function () {
        sessionStorage.setItem(pagesizesession, this.value);
    };
}