import Vue from 'vue'
import axios from 'axios';

module.export = Vue.component('addcontractmodal',
    {
        name: 'addcontract',
        props: {
            data: Object
        },
        components: {},
        data: function () {
            return {
                alerts: [],
                HasBranding: window.hasBranding,
                AddModel: {
                    ContractName: '',
                    CsdEmail: '',
                    Type: ''
                },
                contractList: [],
                TypesDisabled: false,
                Types: {
                    1: { id: 'DLL', val: 'DLL' },
                    2: { id: 'Non DLL', val: 'Non DLL' },
                },
                modalBeingShown: "add",
                dataSource: {
                    data: [],
                    serverPaging: true,
                    serverFiltering: true,
                },
                pagedData: {
                    tokens: {
                        index: -1,
                        values: []
                    },
                    offset: {
                        start: 1,
                        end: undefined
                    },
                    limit: 15,
                    totalCount: 0,
                    isFetching: undefined
                },
                columns: [
                    {
                        'field': 'selected',
                        'title': ' ',
                        'filterable': false,
                        'template':
                            `<div class="checkbox">
                                <input type="checkbox" name="checkbox" #= selected ? \'checked="checked"\' : "" #/>
                                <span class="checkmark"></span>
                            </div>`,
                        'width': '40px'
                    },
                    { 'field': 'dllAgreementNumber', 'title': 'Contract name', 'filterable': false },                    
                    { 'field': 'customerName', 'title': 'Customer name', 'filterable': false }
                ],
                backOffices: [],
                backOfficeFilter: '',
                searchText: ''
            };
        },
        created: function () {
            this.AddModel.Type = (this.HasBranding ? 'DLL' : 'Leased');

            this.Types[Object.keys(this.Types)[0]].val = (this.HasBranding ? 'DLL' : 'Leased');
            this.Types[Object.keys(this.Types)[1]].val = (this.HasBranding ? 'Non DLL' : 'Non Leased');
            this.setBackOfficeReferences();
        },
        mounted: function () {
            if (!this.pagedData.offset.end) {
                this.pagedData.offset.end = this.pagedData.limit;
            }
        },
        watch: {
            modalBeingShown: function () {
                const _this = this;
                _this.clearAlert();
            }
        },
        computed: {
            prevDisabled: function () {
                return !this.hasPrevToken() || this.pagedData.isFetching;
            },
            nextDisabled: function () {
                return !this.hasNextToken() || this.pagedData.isFetching;
            }
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            hasPrevToken: function () {
                return this.pagedData.tokens.index >= 0;
            },
            hasNextToken: function () {
                return this.pagedData.tokens.values.length > 0 &&
                    (this.pagedData.tokens.index + 1) < (this.pagedData.tokens.values.length)
            },
            goToPrevPage: function (evt) {
                evt.preventDefault();

                if (this.hasPrevToken()) {
                    this.pagedData.tokens.values.splice(this.pagedData.tokens.index + 1, 1);

                    this.pagedData.tokens.index -= 1;
                    const pageToken = this.pagedData.tokens.values[this.pagedData.tokens.index];

                    this.fetchPagedData(pageToken, true).then(() => {
                        this.calculateOffset(false);
                    });
                }
            },
            goToNextPage: function (evt) {
                evt.preventDefault();
                if (this.hasNextToken()) {
                    this.pagedData.tokens.index += 1;

                    this.fetchPagedData(this.pagedData.tokens.values[this.pagedData.tokens.index], false).then(() => {
                        this.calculateOffset(true);
                    });
                }
            },
            calculateOffset: function (forward) {
                let newOffset = {};

                if (forward) {
                    newOffset = {
                        start: this.pagedData.offset.start + this.pagedData.limit,
                        end: undefined
                    };

                    newOffset.end = (newOffset.start + this.pagedData.limit) - 1;
                }
                else {
                    newOffset = {
                        start: undefined,
                        end: (this.pagedData.offset.start - 1)
                    };

                    newOffset.start = this.pagedData.offset.start - this.pagedData.limit;
                }

                Object.assign(this.pagedData.offset, newOffset);
            },
            setBackOfficeReferences: function () {
                const _this = this;
                axios.get("/Contracts/GetBackOfficeReferences")
                    .then(response => {
                        _this.backOffices = response.data.data.filter(x => {
                            return (typeof x.source !== "undefined" && typeof x.instance !== "undefined");
                        });
                    }).catch(error => {
                        console.error(error);
                    });
            },
            backOfficeChanged: function () {
                this.pagedData.tokens.index = -1;
                this.pagedData.tokens.values = [];
                this.pagedData.totalCount = 0;

                this.dataSource = {};
                this.pagedData.isFetching = undefined;
                this.fetchPagedData();
            },
            fetchPagedData: function (pageToken, previous, searchTerm) {
                const _this = this;
                const _previous = previous;

                return new Promise((resolve, reject) => {
                    _this.pagedData.isFetching = true;

                    axios.post(
                        '/Contracts/GetContractsFromBackOfficeAssets',
                        {
                            token: pageToken,
                            limit: _this.pagedData.limit
                        },
                        {
                            params: {
                                source: _this.backOfficeFilter.source,
                                instance: _this.backOfficeFilter.instance,
                                search: searchTerm
                            }
                        })
                        .then(response => {
                            _this.dataSource = response.data.items.map((contract) => {
                                return {
                                    selected: (_this.contractList.findIndex(item => item.dllAgreementNumber == contract.dllAgreementNumber) > -1),
                                    dllAgreementNumber: contract.dllAgreementNumber,
                                    customerName: contract.customerName
                                };
                            });

                            if (response.data.pageToken && !_previous) {
                                _this.pagedData.tokens.values.push(response.data.pageToken);
                            }

                            _this.pagedData.totalCount = response.data.totalCount;
                            _this.pagedData.isFetching = false;

                            if (response.data.totalCount > _this.pagedData.limit) {
                                this.pagedData.offset.end = (_this.pagedData.offset.start + _this.pagedData.limit) - 1;
                            }
                            else {
                                this.pagedData.offset.end = response.data.totalCount;
                            }
                            resolve();
                        }).catch(error => {
                            reject(error);
                        });
                });
            },
            searchTextChanged: function () {
                if (this.searchText.trim().length === 0) {
                    this.searchContractList();
                }
            },
            searchContractList: function () {
                if (this.pagedData.isFetching)
                    return;

                this.pagedData.tokens.index = -1;
                this.pagedData.tokens.values = [];
                this.pagedData.totalCount = 0;

                this.fetchPagedData(null, false, this.searchText.trim());
            },
            clearSelection() {
                if (this.$refs.grid){
                    const grid = this.$refs.grid.kendoWidget();
                    this.contractList = [];
                    grid.dataSource.data().forEach(item => item.selected = false);
                    grid.refresh();
                }
            },
            add: function (e) {
                e.preventDefault();
                const _this = this;
                _this.clearAlert();
                $("#saveButton").prop("disabled", true);
                if (this.modalBeingShown == 'search') {
                    this.addContractsFromBackOffice();
                } else {
                    if (_this.AddModel.ContractName) {
                        const re = /^([&$\+,:;=\?@#\s<>\[\]\{\}[\/]|\\\^%])+/;
                        const isInvalidContractName = re.test(String(_this.AddModel.ContractName));
                        if (isInvalidContractName) {
                            _this.addAlert(
                                {
                                    "message": 'Could not add contract due to invalid contract name',
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                            return;
                        }
                    }
                    if (_this.AddModel.CsdEmail) {
                        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        const isValidEmail = re.test(String(_this.AddModel.CsdEmail).toLowerCase());
                        if (isValidEmail) {
                            _this.addContractCall();
                        } else {
                            _this.addAlert(
                                {
                                    "message": 'Could not add contract due to invalid CSD email address',
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    } else {
                        this.addContractCall();
                    }
                }
            },
            addContractsFromBackOffice: function () {
                const _this = this;
                $.ajax({
                    type: 'POST',
                    url: '/Contracts/AddContracts',
                    data: JSON.stringify({
                        contractNames: _this.contractList.map(item => { return item.dllAgreementNumber }),
                        backOfficeSource: _this.backOfficeFilter.source 
                    }),
                    success: function (data) {
                        if (data.success) {
                            _this.close();
                            _this.$emit('contract-added');
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not add contract(s): ${data.data}`,
                                    "isSuccess": false
                                });
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not add contract(s) due to a server error',
                                "isSuccess": false
                            });
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            addContractCall: function () {
                const _this = this;
                _this.clearAlert();

                $.ajax({
                    type: 'POST',
                    url: '/Contracts/Add',
                    data: JSON.stringify({
                        contractName: _this.AddModel.ContractName,
                        csdEmail: _this.AddModel.CsdEmail,
                        type: _this.AddModel.Type,
                    }),
                    success: function (data) {
                        if (data.success) {
                            _this.close();
                            _this.$emit('contract-added');
                        } else {
                            _this.addAlert(
                                {
                                    "message": `Could not add contract: ${data.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    },
                    error: function (data) {
                        _this.addAlert(
                            {
                                "message": 'Could not add contract due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            typeOnChange: function () {
                if (this.AddModel.Type === "Non DLL" || this.AddModel.Type === "Non Leased") {
                    this.AddModel.ContractName = "Non Leased Assets";
                } else if (this.AddModel.ContractName === "Non Leased Assets") {
                    this.AddModel.ContractName = "";
                }
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                this.clearSelection();

                this.AddModel = Object.assign({}, this.data);
                this.AddModel.Type = (this.HasBranding ? 'DLL' : 'Leased');
                this.backOfficeFilter = '';

                this.dataSource = {};
                this.pagedData.isFetching = undefined;

                const $modal = $('#add-contract--add-contract');

                $modal.modal('hide');
                $modal.find("#saveButton").removeAttr("disabled");
            },
            gridOnDatabound: function (e) {
                const _this = this;
                const grid = _this.$refs.grid.kendoWidget();

                // Unregister previous checkbox click events
                grid.element.off('click', '.k-grid-content tr');
                grid.element.on('click', '.k-grid-content tr', function (e) {
                    const item = grid.dataItem($(this).closest("tr[data-uid]"));
                    item.selected = !item.selected;
                    if (item.selected) {
                        _this.contractList.push(item);
                    } else {
                        _this.contractList.splice(_this.contractList.findIndex(i => i.dllAgreementNumber == item.dllAgreementNumber), 1);
                    }
                    grid.refresh();
                });
            }
        }
    });