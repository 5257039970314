import Vue from 'vue'
import VeeValidate from "vee-validate"
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import "../Grid/Grid.less"
import { fetchJson } from '../Shared/request-utils'

if (document.getElementById('vue-mail-settings-overview')) {
    const actionbar = require("../Actionbar/Actionbar.js");
    const alerts = require("../Alerts/Alerts.js");

    require('./Edit.js');

    Vue.use(GridInstaller);
    Vue.use(VeeValidate);

    const pagesizesession = "pagesize";
    let pagesize = sessionStorage.getItem(pagesizesession);
    if (!pagesize) {
        pagesize = 20;
    }

    new Vue({
        el: '#vue-mail-settings-overview',
        components: {},
        data: function () {
            return {
                GridConfiguration: window.preLoadedData.GridConfiguration,
                HasBranding: window.hasBranding,
                ShowSelectBoxes: window.preLoadedData.ShowSelectBoxes,
                AddModel: {
                    companyName: "",
                    countries: window.preLoadedData.Countries,
                    country: "",
                    messageUsEmailAddress: "",
                    portalSuperAdmin: "cap.support@dllgroup.com",
                    companyManagerEmail: "",
                    companyManagerFirstName: "",
                    companyManagerLastName: "",
                    portalUsers: window.preLoadedData.PortalUsers
                },
                EditModel: {
                    companyStatus: ""
                },
                OffboardModel: {},
                dataSource: {
                    data: [],
                    transport: {
                        read: {
                            url: "/Settings/ManageMailSettings/List",
                            contentType: "application/json",
                            type: "POST"
                        },
                        parameterMap: function (options) {
                            return kendo.stringify(options);
                        }
                    },
                    serverPaging: false,
                    serverFiltering: false,
                    serverSorting: false,
                    pageSize: pagesize,
                    schema: {
                        data: "data",
                        total: "total"
                    }
                },
                pageable: {
                    buttonCount: 3,
                    info: true,
                    type: 'numeric',
                    pageSizes: [10, 20, 30, 50],
                    previousNext: true
                },
                columns: {},
                actionbar: window.preLoadedData.Actionbar,

                search: {
                    visible: true
                },
                alerts: [],
                selectedAccounts: [],
                accountCount: 0,
            };
        },
        created: function () {
            this.loadColumns();

            this.actionbar.searchVisible = true;
        },
        mounted: function () {
            this.$refs.grid.kendoWidget();
        },
        methods: {
            actionbarSearch: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                let filter = {};
                if (e !== null && e !== '') {
                    filter = { logic: 'or', filters: [] };
                    grid.columns.forEach(function (x) {
                        if (x.field) {
                            filter.filters.push({
                                field: x.field,
                                operator: 'contains',
                                value: e
                            });
                        }
                    });
                }
                grid.dataSource.filter(filter);
            },

            /**
            * Grid Events
            */
            loadColumns: function (e) {
                this.columns = [
                    { 'field': 'country', 'title': 'Company location country', 'filterable': true, 'width': '70%' },
                    { 'field': 'requestNewLeaseEmail', 'title': 'Request new lease email', 'filterable': false, 'width': '70%' },
                    { 'field': 'messageUsEmail', 'title': 'Message us email', 'filterable': false, 'width': '70%' },
                    { 'field': 'terminationRequestEmail', 'title': 'Asset request - Termination request email', 'filterable': false, 'width': '70%' },
                    { 'field': 'returnRequestEmail', 'title': 'Asset request - Return request email', 'filterable': false, 'width': '70%' },
                    { 'field': 'extensionRequestEmail', 'title': 'Asset request - Extension request email', 'filterable': false, 'width': '70%' },
                    { 'field': 'buyOutRequestEmail', 'title': 'Asset request - Buy Out request email', 'filterable': false, 'width': '70%' },
                    { 'title': ' ', 'template': '<a class="anchor-edit" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--edit" title="Edit"></span></a>', 'filterable': false, 'width': '90px' }
                ];
            },
            addTooltip: function addTooltipToTableHeaders(grid) {
                grid.element[0].querySelectorAll(".k-header").forEach(element => {
                    const title = $(element).data("title");

                    if (!/<[a-z\][\s\S]*>/i.test(title)) {

                        $(element).attr('title', title);
                    }
                });
            },
            refreshGrid: function (message) {
                if (message) {
                    this.addAlert({ "message": message, "isSuccess": true, "showPopup": true });
                }
                const grid = this.$refs.grid.kendoWidget();
                grid.dataSource.read();
            },
            gridOnDatabound: function (e) {
                var _this = this;
                const grid = this.$refs.grid.kendoWidget();

                grid.element.on('click', '.anchor-edit',
                    function (e) {
                        e.preventDefault();
                        const selectedItems = grid.select().toArray().map(function (s) { return grid.dataItem(s).country; });
                        if (selectedItems.length === 1) {
                            fetchJson('./ManageMailSettings/GetMailSettings?country=' + selectedItems[0])
                                .then(
                                    (result) => {
                                        _this.EditModel = Object.assign({}, result);
                                        _this.$refs.editmailsettings.show(_this.EditModel);
                                    },
                                    (error) => {
                                        console.error(error);
                                });
                        }
                    });

                this.addTooltip(grid);
            },

            /**
             * Alerts
             */
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
        }
    });
}