import Vue from 'vue'
import VeeValidate from "vee-validate"
import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import { toArray } from 'lodash';
import "../Grid/Grid.less"

if (document.getElementById('vue-role-overview')) {
    const actionbar = require("../Actionbar/Actionbar.js");
    const alerts = require("../Alerts/Alerts.js");
    require("./Add.js");
    require("./Edit.js");
    
    Vue.use(GridInstaller);
    Vue.use(VeeValidate);

    const pagesizesession = "pagesize";
    let pagesize = sessionStorage.getItem(pagesizesession);
    if (!pagesize) {
        pagesize = 20;
    }

    new Vue({
        el: '#vue-role-overview',
        components: {},
        data: function () {
            return {
                dataSource: {
                    data: window.preLoadedData.AllRights
                },
                AddModel: {
                    name: '',
                    allRights: window.preLoadedData.AllRights
                },
                EditModel: {
                    name: "",
                    rights: window.preLoadedData.AllRights
                },
                dataSource: {
                    data: [],
                    transport: {
                        read: {
                            url: "/Settings/ManageRoles/List",
                            contentType: "application/json",
                            type: "POST"
                        },
                        parameterMap: function (options) {
                            return kendo.stringify(options);
                        }
                    },
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    pageSize: pagesize,
                    schema: {
                        data: "data",
                        total: "total"
                    }
                },
                pageable: {
                    buttonCount: 3,
                    info: true,
                    type: 'numeric',
                    pageSizes: [10, 20, 30, 50],
                    previousNext: true
                },
                columns: {},
                actionbar: window.preLoadedData.Actionbar,

                search: {
                    visible: true
                },
                selectedAssets: [],
                alerts: []
            };
        },
        created: function () {
            this.loadColumns();

            this.actionbar.searchVisible = true;

            const selectAll = this.actionbar.ItemsRight.find(f => f.event === "ab-select-all") ? this.actionbar.ItemsRight.find(f => f.event === "ab-select-all") : this.actionbar.ItemsLeft.find(f => f.event === "ab-select-all");
            if (selectAll != null) {
                selectAll.visible = false;

                const emptySpaceBeforeSelectAll = this.actionbar.ItemsLeft.find(f => f.event === null);
                if (emptySpaceBeforeSelectAll != null) {
                    emptySpaceBeforeSelectAll.visible = false;
                }
            }
        },
        methods: {
            refreshGrid: function () {
                const grid = this.$refs.grid.kendoWidget();
                grid.dataSource.read();
            },
            addAlert: function (event) {
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            loadColumns: function (e) {
                this.columns = [
                    { 'field': 'name', 'title': 'Name', 'filterable': false, 'width': '20%' },
                    { 'field': 'rights', 'title': 'Responsibilities', 'filterable': false, 'width': '70%' }
                ]
                let rowactions = '';
                //add conditions to add following 

                rowactions += '<a class="anchor-edit" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--edit" title="Edit"></span></a>';
                rowactions += '<a class="anchor-remove" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--trash-can" title="Remove role"></span></a>';
                if (rowactions != '') {
                    this.columns.push({ 'title': ' ', 'filterable': false, 'template': rowactions, 'width': '90x' });
                }
            },
            addTooltip: function addTooltipToTableHeaders(grid) {
                grid.element[0].querySelectorAll(".k-header").forEach(element => {
                    const title = $(element).data("title");

                    if (!/<[a-z\][\s\S]*>/i.test(title)) {

                        $(element).attr('title', title);
                    }
                });
            },
            actionbarSearch: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                let filter = {};
                if (e !== null && e !== '') {
                    filter = { logic: 'or', filters: [] };
                    grid.columns.forEach(function (x) {
                        if (x.field) {
                            filter.filters.push({
                                field: x.field,
                                operator: 'contains',
                                value: e
                            });
                        }
                    });
                }
                grid.dataSource.filter(filter);
            },
            actionbarSelectAll: function () {
            },
            actionbarDownloadRRReport: function () {
                window.open("/Settings/ManageRoles/DownloadRandRReport", "_blank");
            },
            confirmedDeleteRole: function (e) {
                const _this = this;

                $.ajax({
                    type: 'POST',
                    url: '/Settings/ManageRoles/Delete',
                    data: JSON.stringify({
                        roleIds: [window.Role.Id]
                    }),
                    contentType: "application/json",
                    dataType: 'json',
                    success: function (data) {
                        $('#roleoverview-delete-role').modal('hide');

                        if (!data.success) {
                            _this.addAlert({
                                "message": data.message,
                                "isSuccess": false
                            });
                        } else {
                            _this.refreshGrid();
                        }
                    }
                });
            },
            actionbarAddRole: function (e) {
                $('#roleoverview-add-role').modal('show');
            },
            actionbarDeleteRole: function () {
                const grid = this.$refs.grid.kendoWidget();
                const selectedItems = grid.dataSource.data().filter(i => i.selected);
                if (selectedItems.length >= 1) {
                    $('#roleoverview-delete-role').modal('show');
                } else {
                    alert('Please select one or more roles\'s to delete');
                }
            },
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                grid.element.unbind('dblclick');
                grid.element.unbind('click');
                
                const _this = this;

                grid.element.on('dblclick', 'tbody tr[data-uid]',
                    function (e) {
                        e.preventDefault();

                        const role = grid.dataItem($(this).closest("tr[data-uid"));

                        window.Role = {
                            Id: role.id,
                            Name: role.name,
                            Rights: role.rights.split(' / ')
                        };;
                        _this.$refs.editrole.EditModel.name = role.name;
                        _this.$refs.editrole.$refs.grid.kendoWidget().dataSource.data([]);
                        const allRights = window.preLoadedData.AllRights;
                        const rightsWithSelection = allRights;
                        for (let i = 0; i < allRights.length; i++) {
                            const matchedIndex = window.Role.Rights.findIndex(x => x === allRights[i].Name);
                            if (matchedIndex !== -1) {
                                rightsWithSelection[i].Selected = true;
                            }
                        }
                        _this.$refs.editrole.EditModel.rights = rightsWithSelection;
                        _this.$refs.editrole.$refs.grid.kendoWidget().dataSource.read();
                        $('#roleoverview-edit-role').modal('show');
                    });

                //actionview handlers:
                grid.element.on('click', '.anchor-remove',
                    function (e) {
                        e.preventDefault();
                        const role = grid.dataItem($(this).closest("tr[data-uid"));

                        window.Role = {
                            Id: role.id,
                            Name: role.name,
                            Rights: role.rights.split(' / ')
                        };
                        $('#roleoverview-delete-role').modal('show');
                    });
                grid.element.on('click', '.anchor-edit',
                    function (e) {
                        e.preventDefault();
                        const role = grid.dataItem($(this).closest("tr[data-uid"));

                        window.Role = {
                            Id: role.id,
                            Name: role.name,
                            Rights: role.rights.split(' / ')
                        };
                        _this.$refs.editrole.EditModel.name = role.name;
                        _this.$refs.editrole.$refs.grid.kendoWidget().dataSource.data([]);
                        const allRights = window.preLoadedData.AllRights;
                        const rightsWithSelection = allRights;
                        for (let i = 0; i < allRights.length; i++) {
                            const matchedIndex = window.Role.Rights.findIndex(x => x === allRights[i].Name);
                            if (matchedIndex !== -1) {
                                rightsWithSelection[i].Selected = true;
                            }
                        }
                        _this.$refs.editrole.EditModel.rights = rightsWithSelection;
                        _this.$refs.editrole.$refs.grid.kendoWidget().dataSource.read();
                        $('#roleoverview-edit-role').modal('show');
                    });

                this.addTooltip(grid);
            }
        }
    });
    $('.k-header').dblclick(function (e) {
        if (e.offsetY > $(this).outerHeight() - 2) {
            this.width = getTextWidth(this.textContent, this.font);
            //get column number
            const colNr = this.getAttribute("data-index");
            //select column
            //add search in class k-grid-content
            const gridCols = document.getElementById("role-overview-grid").getElementsByClassName("k-grid-content")[0].querySelector("colgroup").childNodes;
            gridCols[colNr].width = $(this).outerWidth();
        }
    })

    function getTextWidth(text, font) {
        // re-use canvas object for better performance
        const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");
        context.font = font;
        const metrics = context.measureText(text);
        //add space for the filter and arroww
        const tableIcons = 80;
        const total = metrics.width + tableIcons;
        return total;
    }
    const pageSizeDropDownList = document.getElementsByClassName('k-pager-sizes')[0].querySelector("[data-role=dropdownlist]");
    pageSizeDropDownList.onchange = function () {
        sessionStorage.setItem(pagesizesession, this.value);
    };
}