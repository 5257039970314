import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import axios from "axios"
import { ReadingTypes } from './ReadingTypes.js'

module.export = Vue.component('addMeterReadingModal',
    {
        name: 'addMeterReadingModal',
        props: {
            asset: Object,
            enabledTypes: Array,
            sourceSystem: String,
            active: Boolean
        },
        components: {
            DatePicker
        },
        data: function () {
            return {
                alerts: [],
                relatedAsset: this.asset,
                lastReadingValue: "-",
                formData: new FormData(),
                selectedImage: null,
                newItem: {
                    Date: null,
                    Type: null,
                    Value: null,
                    Image: null
                },
                types: Object.assign([], this.enabledTypes.sort(x => x.displayName)),
                isSaved: false,
                isUploading: undefined,
                source: this.sourceSystem
            };
        },
        watch: {
            'asset': function (newValue) {
                this.relatedAsset = newValue;
            },
            'enabledTypes': function (newValue) {
                if (this.types.length === 0 && newValue.length > 0) {
                    this.types = Object.assign([], newValue.sort(x => x.displayName));
                }
            },
            'sourceSystem': function (newValue) {
                if (newValue) {
                    this.source = newValue;
                }
            },
            'active': function (newValue) {
                if (newValue) {
                    this.relatedAsset = this.asset;
                    this.determineLastReadingValue();
                }
            }
        },
        mounted: function () {
            this.determineLastReadingValue();
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            determineLastReadingValue: function () {
                if (this.relatedAsset && this.relatedAsset.MeterReadings.Values.length > 0) {
                    const sortedReadings = this.relatedAsset.MeterReadings.Values.sort((a, b) => {
                        return new Date(b.Date) - new Date(a.Date);
                    });
                    const readingValue = sortedReadings[0];

                    if (readingValue) {
                        let formattedReadingValue = "";
                        const readingType = ReadingTypes.get(readingValue.Type);

                        if (readingType.prefix && readingType.prefix.length > 0)
                            formattedReadingValue = readingType.prefix + " ";

                        formattedReadingValue += readingValue.Value;

                        if (readingType.suffix && readingType.suffix.length > 0)
                            formattedReadingValue += " " + readingType.suffix;

                        this.lastReadingValue = formattedReadingValue;
                    }
                }
            },
            resetForm: function () {
                this.formData = new FormData();
                this.lastReadingValue = "-";
                this.newItem = {
                    Date: null,
                    Type: null,
                    Value: null,
                    Attachment: null
                };
                this.clearAlert();
                this.isSaved = false;
                this.isUploading = undefined;
                const form = document.getElementById("addForm");
                this.errors.clear();
                form.reset();
                this.$validator.reset();
            },
            validateBeforeSubmit: function (event) {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        $("#saveButton").prop("disabled", true);
                        this.isUploading = true;

                        this.formData.set("assetId", this.relatedAsset.Id);
                        this.formData.set("type", this.newItem.Type);
                        this.formData.set("date", this.newItem.Date);
                        this.formData.set("value", this.newItem.Value);
                        this.formData.set("sourceSystem", this.source);

                        axios.post('/MeterReadings/Add',
                            this.formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }).then((response) => {
                                this.isUploading = false;
                                if (response.data.success) {
                                    this.isSaved = true;
                                    $("#saveButton").prop("disabled", false)
                                    $('#asset-details--add-meter-reading').modal('hide');
                                    this.$emit('meter-reading-added', "Reading was registered successfully", "ADDED", Object.assign({}, this.newItem));
                                    this.$emit('modal-closed');
                                    this.resetForm();
                                } else {
                                    this.addAlert(
                                        {
                                            "message": `Could not add meter reading: ${response.data.data}`,
                                            "isSuccess": false
                                        });
                                }
                            }).catch(error => {
                                this.isUploading = false;
                                $("#saveButton").prop("disabled", false)
                                console.error(error);
                                this.isSaved = false;
                                if (error.message === 'Network Error') {
                                    this.addAlert(
                                        {
                                            "message": 'Uploading and scanning file took longer than expected. Please refresh page to see if upload was successful',
                                            "isSuccess": false
                                        });
                                } else {
                                    this.addAlert(
                                        {
                                            "message": 'Could not add document due to a server error',
                                            "isSuccess": false
                                        });
                                }
                            });
                        return;
                    } else {
                        this.addAlert(
                            {
                                "message": 'Some fields in the form are invalid',
                                "isSuccess": false
                            });
                    }
                });
            },
            imageChanged: function (e) {
                if (e.target.files.length === 1) {
                    const selectedFile = e.target.files[0];
                    this.newItem.Attachment = selectedFile;

                    this.formData.set("file", this.newItem.Attachment, this.newItem.Attachment.name)
                }
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.resetForm();
                $('#asset-details--add-meter-reading').modal('hide');
                this.$emit('modal-closed');
            },
        }
    });