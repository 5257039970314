import Vue from 'vue'
import DatePicker from "vue2-datepicker"
import 'vue2-datepicker/index.css'
import axios from "axios"

module.export = Vue.component('assetterminationmodal',
    {
        name: "assetterminationmodal",
        props: {
            selectedAssets: Array,
            assetCount: Number,
            assetsToBeTerminatedCount: Number,
            assetsBackOfficeCount: Number,
            assetsBackOfficeIds: Array,
            assetsTerminatedCount: Number,
            assetsShouldBeExcluded: Boolean,
            assetFilter: Object
        },
        components: {
            DatePicker
        },
        data: function () {
            return {
                alerts: [],
                ShowProgress: false,
                ShowSuccess: false,
                resultText: '',
                terminationNotification: '',
                terminationProgressPercentage: 0,
                assetTermination: {
                    TerminationDate: null
                },
                failedAssetIds: [],
            };
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            save: function () {
                this.clearAlert();
                const vueObject = this;
                $("#saveButton").prop("disabled", true);
                if (!this.assetsShouldBeExcluded) {
                    const selectedAssets = vueObject.selectedAssets.map((a) => ({
                        id: a.id,
                        contractId: a.contractId,
                        status: a.assetStatusDll,
                        isBackofficeAsset: a.assetReferences != null
                    })).filter(a => !a.isBackofficeAsset);
                    this.terminateAssets(vueObject, selectedAssets).then((response) => {
                        if (response.success) {
                            vueObject.resultText = "Successfully terminated " + response.successfulTerminationCount + " assets. " + (response.alreadyTerminatedCount > 0 ? response.alreadyTerminatedCount + " were already terminated. " : "") + (response.unsuccessfulTerminatedCount > 0 ? response.unsuccessfulTerminatedCount + " Could not be terminated. Termination needs to be done in backoffice." : "");
                            if (response.failedAssetIds.length > 0) {
                                vueObject.failedAssetIds.push(...response.failedAssetIds);
                            }
                            vueObject.ShowProgress = true;
                        } else {
                            vueObject.addAlert(
                                {
                                    "message": `Could not terminate asset: ${response.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                    },
                    (error) => {
                        vueObject.addAlert(
                            {
                                "message": 'Could not terminate asset due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    });
                } else {
                    this.fetchPagedTerminationData().then((response) => {
                        this.terminateAssetsFromResponseRecursively(vueObject, response);
                    },
                    (error) => {
                        vueObject.addAlert(
                            {
                                "message": 'Could not terminate asset due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    });
                }
            },
            terminateAssets: function(vueObject, selectedAssets) {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        type: 'POST',
                        url: '/AssetTermination/TerminateAssets',
                        data: JSON.stringify({
                            AssetsToTerminate: selectedAssets,
                            TerminationDate: vueObject.assetTermination.TerminationDate
                        }),
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            fetchPagedTerminationData: function (pageToken) {
                return new Promise((resolve, reject) => { 
                    $.ajax({
                        type: 'POST',
                        url: '/LeasedAssets/QueryTerminationRemovalData',
                        data: JSON.stringify(({ 
                            token: pageToken,
                            limit: 250,
                            filter: this.assetFilter
                        })),
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            terminateAssetsFromResponseRecursively: function (vueObject, response) {
                if (response) {
                    const token = response.pageToken;
                    const selectedAssets = response.items.filter(a => !this.selectedAssets.some(a2 => a2.id === a.id)).map((a) => ({
                        id: a.id,
                        contractId: a.contractId,
                        status: a.status,
                        isBackofficeAsset: a.isBackofficeAsset
                    })).filter(a => !a.isBackofficeAsset);
                    
                    this.terminateAssets(vueObject, selectedAssets).then((terminationResponse) => {
                        if (terminationResponse.success) {
                            vueObject.resultText = "Successfully terminated " + terminationResponse.successfulTerminationCount + " assets. " + (terminationResponse.alreadyTerminatedCount > 0 ? terminationResponse.alreadyTerminatedCount + " were already terminated. " : "") + (terminationResponse.unsuccessfulTerminatedCount > 0 ? terminationResponse.unsuccessfulTerminatedCount + " Could not be terminated. Termination needs to be done in backoffice." : "");
                            if (terminationResponse.failedAssetIds.length > 0) {
                                vueObject.failedAssetIds.push(...terminationResponse.failedAssetIds);
                            }
                            vueObject.ShowProgress = true;

                        } else {
                            vueObject.addAlert(
                                {
                                    "message": `Could not terminate asset: ${terminationResponse.data}`,
                                    "isSuccess": false
                                });
                            $("#saveButton").removeAttr("disabled");
                        }
                        if (token) {
                            this.fetchPagedTerminationData(token).then((response) => {
                                this.terminateAssetsFromResponseRecursively(vueObject, response);
                            },
                            (error) => {
                                vueObject.addAlert(
                                    {
                                        "message": 'Could not terminate asset due to a server error',
                                        "isSuccess": false
                                    });
                                $("#saveButton").removeAttr("disabled");
                            });      
                        }      
                    },
                    (error) => {
                        vueObject.addAlert(
                            {
                                "message": 'Could not terminate asset due to a server error',
                                "isSuccess": false
                            });
                        $("#saveButton").removeAttr("disabled");
                    });
                }
            },
            download: function () {
                axios.get('/LeasedAssets/GetBackOfficeAssetsExcelFile?backOfficeAssetsIds=' + this.assetsBackOfficeIds.join(','), { responseType: 'blob' })
                    .then(response => {
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(response.data);
                        link.download = 'Back-Office-Assets-To-Be-Terminated_' + new Date().toISOString() + '.xlsx';
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }).catch(console.error);
            },
            finish: function () {
                this.$emit('refresh');
                this.close();
            },
            close: function () {
                this.clearAlert();
                this.ShowProgress = false;
                this.ShowSuccess = false;
                $('#asset-termination-modal').modal('hide');
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            show: function() {
                const _this = this;
                $('#asset-termination-modal').modal('show');
                this.terminationNotification = '';
                this.terminationProgressPercentage = 0;
                const notifications = window.assetNotificationsHub;
                notifications.on("NotifyTerminationStarted", function (terminationid, total) {
                    _this.terminationNotification = `Started terminating ${total} assets.`;
                });
                notifications.on("NotifyTerminationProgress", function (terminationid, progress, total) {
                    _this.terminationProgressPercentage = parseInt(progress * 100 / total);
                });
                notifications.on("NotifyTerminationCompleted", function (terminationid, total) {
                    _this.terminationNotification = `${total} assets successfully terminated!`;
                    _this.ShowProgress = false;
                    _this.ShowSuccess = true;
                });
            }
        }
    });
