import Vue from 'vue'
import VeeValidate from "vee-validate"
import axios from 'axios';
import "./Index.less"
import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'

if (document.getElementById('vue-asset-types-overview')) {
    const actionbar = require("../Actionbar/Actionbar.js");
    const alerts = require("../Alerts/Alerts.js");
    require("./Add.js");
    require("./Edit.js");
    require("./SetDefaultRights.js");

    Vue.use(GridInstaller);
    Vue.use(VeeValidate);

    const pagesizesession = "pagesize";
    let pagesize = sessionStorage.getItem(pagesizesession);
    if (!pagesize) {
        pagesize = 20;        
    }

    const v = new Vue({
        el: '#vue-asset-types-overview',
        components: {},
        data: function () {
            return {
                AddModel: {
                    AssetTypeName: '',
                    MasterAssetTypeName: '',
                    DefaultFieldsToBeHidden: null,
                },
                EditModel: window.preLoadedData.DefaultFields,
                dataSource: { //new kendo.data.DataSource({
                    data: [],
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    sort: { field: "assetTypeName", dir: "asc" },
                    transport: {
                        fetchPagedData: undefined,
                        pagedData: undefined,
                        read: function (options) {
                            let pageToken = null;

                            const sortingHasChanged = this.pagedData.previousSort && options.data.sort && this.pagedData.previousSort.length == 1 && options.data.sort.length == 1 && (this.pagedData.previousSort[0].dir !== options.data.sort[0].dir || this.pagedData.previousSort[0].field !== options.data.sort[0].field);

                            if (this.pagedData.isApplyingFilter) {
                                this.pagedData.offset.start = 1;
                                this.pagedData.tokens.values = [];
                                this.pagedData.tokens.index = -1;
                            }

                            if (this.pagedData.isApplyingFilter) {
                                this.pagedData.isApplyingFilter = undefined;
                            }

                            if (((this.pagedData.previousSort == undefined || this.pagedData.previousSort.length == 0) && options.data.sort) ||
                                ((options.data.sort == undefined || options.data.sort.length == 0) && this.pagedData.previousSort) ||
                                sortingHasChanged) {
                                this.pagedData.offset.start = 1;
                                this.pagedData.tokens.values = [];
                                this.pagedData.tokens.index = -1;
                                pageToken = null;
                            }
                            this.pagedData.previousSort = options.data.sort;

                            this.fetchPagedData(pageToken).then(
                                (response) => {
                                    options.success(response);
                                },
                                (error) => {
                                    options.error(error);
                                }
                            );
                        }
                    },
                    pageSize: pagesize,
                    schema: {
                        data: "items",
                        total: "totalCount",
                        model: {
                            id: "id",
                            fields: {}
                        }
                    }
                },//}),
                pagedData: {
                    tokens: {
                        index: -1,
                        values: []
                    }, 
                    offset: {
                        start: 1,
                        end: undefined
                    },
                    limit: 20,
                    totalCount: 0,
                    isFetching: undefined,
                    isApplyingFilter: undefined,
                    previousSort: undefined
                },
                pageSizes: {
                    default: 20,
                    values: [10, 20, 30, 50]
                },
                columns: [
                    {
                        'field': 'selected',
                        'title': ' ',
                        'filterable': false,
                        'sortable': false,
                        'headerTemplate':
                            `<div>
                                <a class="visual-checkbox" href="\\#">
                                    <input type="checkbox" />
                                    <span class="checkmark"></span>
                                </a>
                            </div>`,
                        'template':
                            `<div #= selected ? \'class="state-selected"\' : "" #>
                                <a class="visual-checkbox" href="\\#">
                                    <input type="checkbox" #= selected ? \'checked="checked"\' : "" # />
                                    <span class="checkmark"></span>
                                </a>
                            </div>`,
                        'width': '40px'
                    },
                    { 'field': 'assetTypeName', 'title': 'Asset Type', 'filterable': false },
                    { 'field': 'masterAssetTypeName', 'title': 'Master Asset Type', 'filterable': false },
                    { 'field': 'numberOfFields', 'title': 'Number of fields', 'filterable': false, 'sortable': false }
                ],
                actionbar: window.preLoadedData.Actionbar,
                search: {
                    visible: true
                },
                alerts: []
            };
        },
        created: function () {
            this.actionbar.searchVisible = true;
            this.dataSource.transport.fetchPagedData = this.fetchPagedData;
            this.dataSource.transport.pagedData = this.pagedData;
        },
        mounted: function () {
            const deleteAssetTypeButton = this.actionbar.ItemsRight.find(f => f.event === "ab-delete-asset-types") ? this.actionbar.ItemsRight.find(f => f.event === "ab-delete-asset-types") : this.actionbar.ItemsLeft.find(f => f.event === "ab-delete-asset-types");
            if (deleteAssetTypeButton != null) {
                deleteAssetTypeButton.visible = window.preLoadedData.rights.deleteAssetType;
            }

            const addAssetTypeButton = this.actionbar.ItemsRight.find(f => f.event === "ab-add-asset-type") ? this.actionbar.ItemsRight.find(f => f.event === "ab-add-asset-type") : this.actionbar.ItemsLeft.find(f => f.event === "ab-add-asset-type");
            if (addAssetTypeButton != null) {
                addAssetTypeButton.visible = window.preLoadedData.rights.addAssetType;
            }

            const setDefaultRightsButton = this.actionbar.ItemsRight.find(f => f.event === "ab-set-default-rights") ? this.actionbar.ItemsRight.find(f => f.event === "ab-set-default-rights") : this.actionbar.ItemsLeft.find(f => f.event === "ab-set-default-rights");
            if (setDefaultRightsButton != null) {
                setDefaultRightsButton.visible = window.preLoadedData.rights.setDefaultRights;
            }

            const downloadComplianceButton = this.actionbar.ItemsRight.find(f => f.event === "ab-download-compliance") ? this.actionbar.ItemsRight.find(f => f.event === "ab-download-compliance") : this.actionbar.ItemsLeft.find(f => f.event === "ab-download-compliance");
            if (downloadComplianceButton != null) {
                downloadComplianceButton.visible = window.preLoadedData.rights.downloadCompliance;
            }

            const grid = this.$refs.grid.kendoWidget();
            const _this = this;

            const $pageSizeSelector = $("#pageSizeSelector");

            $pageSizeSelector.kendoDropDownList({
                dataSource: _this.pageSizes.values,
                change: function () {
                    if (!_this.pagedData.isFetching) {
                        const newPageSize = parseInt($pageSizeSelector.val());
                        const pageToken = _this.hasPrevToken() ? _this.pagedData.tokens.values[_this.pagedData.tokens.index] : null;
                        // Only refetch the data when the page size is actually different.
                        if (newPageSize !== _this.pagedData.limit) {
                            _this.pagedData.limit = newPageSize;
                            _this.refreshData();
                            sessionStorage.setItem(pagesizesession, newPageSize);
                        }
                    }
                }
            });

            grid.element.on('click', '.visual-checkbox',
                function (e) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    const cb = this.querySelector("input[type='checkbox']");
                    cb.checked = !cb.checked;
                    if (cb.checked) {
                        $(this.parentElement).addClass("state-selected");
                    } else {
                        $(this.parentElement).removeClass("state-selected");
                    }
                    const dataItem = grid.dataItem($(this).closest("tr[data-uid"));
                    if (dataItem) {
                        dataItem.selected = cb.checked;
                    } else {
                        grid.dataSource.data().forEach(item => item.selected = cb.checked );
                    }

                    _this.selectOnChange();
                    grid.refresh();
                });
            
            if (!this.pagedData.offset.end) {
                this.pagedData.offset.end = this.pagedData.limit;
            }
        },
        computed: {
            columnsWithRights: function () {
                let columnsWithRights = JSON.parse(JSON.stringify(this.columns));
                if (window.preLoadedData.rights.addAssetType) {
                    columnsWithRights.push({ 'title': ' ', 'filterable': false, 'template': '<a class="anchor-view" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--edit" title="Edit"></span></a>', 'width': '90px' });
                } else {
                    columnsWithRights.push({ 'title': ' ', 'filterable': false, 'template': '<a class="anchor-view" href="\\#"><span class="icon-rowaction icon-rowaction--dark icon-rowaction--view" title="View"></span></a>', 'width': '90px' });
                }
                return columnsWithRights;
            },
            prevDisabled: function () {
                return !this.hasPrevToken() || this.pagedData.isFetching;
            },
            nextDisabled: function () {
                return !this.hasNextToken() || this.pagedData.isFetching;
            }   
        },
        methods: {
            hasPrevToken: function () {
                return this.pagedData.tokens.index >= 0;
            },
            hasNextToken: function () {
                return this.pagedData.tokens.values.length > 0 &&
                    (this.pagedData.tokens.index + 1) < (this.pagedData.tokens.values.length)
            },
            goToPrevPage: function (evt) {
                evt.preventDefault();
                const grid = this.$refs.grid.kendoWidget();
                if (this.hasPrevToken()) {
                    this.pagedData.tokens.values.splice(this.pagedData.tokens.index + 1, 1);

                    this.pagedData.tokens.index -= 1;
                    const pageToken = this.pagedData.tokens.values[this.pagedData.tokens.index];

                    this.fetchPagedData(pageToken, true).then((response) => {
                        grid.dataSource.data(response.items);
                        this.calculateOffset(false);
                    });
                }
            },
            goToNextPage: function (evt) {
                evt.preventDefault();
                const grid = this.$refs.grid.kendoWidget();
                if (this.hasNextToken()) {
                    this.pagedData.tokens.index += 1;
                    this.fetchPagedData(this.pagedData.tokens.values[this.pagedData.tokens.index], false).then((response) => {
                        grid.dataSource.data(response.items);
                        this.calculateOffset(true);
                    });
                }
            },
            calculateOffset: function (forward) {
                let newOffset = {};

                if (forward) {
                    newOffset = {
                        start: this.pagedData.offset.start + this.pagedData.limit,
                        end: undefined
                    };

                    newOffset.end = (newOffset.start + this.pagedData.limit) - 1;
                }
                else {
                    newOffset = {
                        start: undefined,
                        end: (this.pagedData.offset.start - 1)
                    };

                    newOffset.start = this.pagedData.offset.start - this.pagedData.limit;
                }

                Object.assign(this.pagedData.offset, newOffset);
            },            
            fetchPagedData: function (pageToken, previous) {
                const _this = this;
                const _previous = previous;
                return new Promise((resolve, reject) => {
                    const grid = this.$refs.grid.kendoWidget();
                    const filter = grid.dataSource.filter();
                    const sort = grid.dataSource.sort();
                    _this.pagedData.isFetching = true;
                    
                    axios.post(
                        '/AssetTypes/Query',
                        {
                            token: pageToken,
                            limit: _this.pagedData.limit,
                            filter: filter,
                            sort: sort
                        })
                        .then(response => {
                            if (response.data.pageToken && !_previous) {
                                _this.pagedData.tokens.values.push(response.data.pageToken);
                            }

                            _this.pagedData.totalCount = response.data.totalCount;
                            _this.pagedData.isFetching = false;

                            if (response.data.totalCount > _this.pagedData.limit) {
                                this.pagedData.offset.end = (_this.pagedData.offset.start + _this.pagedData.limit) - 1;
                            }
                            else {
                                this.pagedData.offset.end = response.data.totalCount;
                            }
                            resolve(response.data);
                        }).catch(error => {
                            reject(error);
                        });
                });
            },
            /**
             * Refreshes the data in the grid and resets the selected page offset to the start.
             */
            refreshData: function () {
                const grid = this.$refs.grid.kendoWidget();

                this.pagedData.tokens = {
                    index: -1,
                    values: []
                };

                this.pagedData.offset = {
                    start: 1,
                    end: undefined
                };

                this.fetchPagedData().then(
                    (response) => {
                        grid.dataSource.data(response.items);
                    },
                    (error) => {
                        console.error(error);
                    });
            },
            refreshGrid: function () {
                const grid = this.$refs.grid.kendoWidget();
                grid.dataSource.read();
            },
            RedirectToDetails: function (id) {
                window.location.href = "./AssetTypes/Details?id=" + id;
            },
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            addTooltip: function addTooltipToTableHeaders(grid) {
                grid.element[0].querySelectorAll(".k-header").forEach(element => {
                    const title = $(element).data("title");

                    if (!/<[a-z\][\s\S]*>/i.test(title)) {

                        $(element).attr('title', title);
                    }
                });
            },
            actionbarSelectAll: function (checked) {
                const grid = this.$refs.grid.kendoWidget();
                if (checked) {
                    grid.dataSource.data().forEach(i => i.selected = true);
                } else {
                    grid.dataSource.data().forEach(i => i.selected = false);
                }
                this.setHeaderSelectAll(grid, checked);
                this.selectOnChange();
                grid.refresh();
            },
            setHeaderSelectAll: function (grid, checked)
            {
                const cb = grid.element[0].querySelector(".k-grid-header .visual-checkbox input[type='checkbox']");
                cb.checked = checked;
                if (cb.checked) {
                    $(cb.parentElement.parentElement).addClass("state-selected");

                } else {
                    $(cb.parentElement.parentElement).removeClass("state-selected");
                }
            },
            actionbarDownloadCompliance: function () {
                window.open("/AssetTypes/DownloadCompliance", "_blank");
            },
            actionbarAddAssetType: function () {
                $('#add-asset-type').modal('show');
            },

            actionbarSetDefaultRights: function () {
                $('#set-default-rights').modal('show');
            },
            actionbarDeleteAssetTypes: function () {
                const grid = this.$refs.grid.kendoWidget();
                const selectedItems = grid.dataSource.data().filter(i => i.selected);
                if (selectedItems.length >= 1) {
                    $('#delete-asset-type').modal('show');
                } else {
                    alert('Please select one or more Asset Type\'s to delete');
                }
            },
            confirmedDeleteAssetType: function () {
                const grid = this.$refs.grid.kendoWidget();
                const selectedItems = grid.dataSource.data().filter(i => i.selected).map(i => i.id);
                const _this = this;
                $.ajax({
                    type: "POST",
                    url: "/AssetTypes/Delete",
                    data: JSON.stringify({
                        assetTypeIds: selectedItems
                    }),
                    success: function (data) {
                        $("#delete-asset-type").modal('hide');

                        if (!data.success) {
                            _this.addAlert({
                                "message": data.message,
                                "isSuccess": false
                            });
                        }
                        else {
                            _this.refreshGrid();
                        }
                    },
                    error: function (data) {
                        _this.addAlert({
                            "message": "Could not delete Asset Type(\'s)!",
                            "isSuccess": false
                        });
                    },
                    contentType: "application/json",
                    dataType: 'json'
                });
            },
            actionbarSearch: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                grid.dataSource.transport.pagedData.isApplyingFilter = true;
                let filter = {};
                if (e !== null && e !== '') {
                    filter = { logic: 'or', filters: [] };
                    grid.columns.forEach(function (x) {
                        if (x.field) {
                            filter.filters.push({
                                field: x.field,
                                operator: 'contains',
                                value: e.trim()
                            });
                        }
                    });
                }
                grid.dataSource.filter(filter);
            },
            gridOnDatabound: function (e) {
                const grid = this.$refs.grid.kendoWidget();
                grid.element.unbind('dblclick');

                grid.element.on('dblclick', 'tbody tr[data-uid]',
                    function (e) {
                        e.preventDefault();
                        e.stopImmediatePropagation();

                        const selectedItems = grid.select().toArray().map(function (s) { return grid.dataItem(s).id; });
                        if (selectedItems.length === 1) {
                            window.location.href = "./AssetTypes/Details?id=" + selectedItems[0];
                        }
                    });

                grid.element.on('click', '.anchor-view',
                    function (e) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        const selectedItems = grid.select().toArray().map(function (s) { return grid.dataItem(s).id; });
                        if (selectedItems.length === 1) {
                            window.location.href = "./AssetTypes/Details?id=" + selectedItems[0];
                        }
                    });

                grid.element.on('click', '.anchor-remove', function (e) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    const assetType = grid.dataItem($(this).closest("tr[data-uid"));
                    $.ajax({
                        type: 'POST',
                        url: '/AssetTypes/Delete',
                        data: JSON.stringify({
                            assetTypeIds: [assetType.Id]
                        }),
                        success: function (data) {
                            if (data.success) {
                                location.reload();
                            } else {
                                _this.$emit('alert',
                                    {
                                        "message": "Could not remove Asset Type.",
                                        "isSuccess": false
                                    });
                                _this.ShowError = true;
                            }
                        },
                        error: function (data) {
                            _this.ShowError = true;
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });

                this.addTooltip(grid);
            },
            selectOnChange: function () {
                const grid = this.$refs.grid.kendoWidget();
                const selected = grid.dataSource.data().filter(i => i.selected);

                const selectAll = document.getElementById('actionbarSelectAll');
                if (selectAll.checked && selected.length < grid.tbody[0].rows.length) {
                    selectAll.checked = false;
                }
            }
        }
    });
    $('.k-header').dblclick(function (e) {
        if (e.offsetY > $(this).outerHeight() - 2) {
            this.width = getTextWidth(this.textContent, this.font);
            //get column number
            const colNr = this.getAttribute("data-index");
            //select column
            //add search in class k-grid-content
            let gridCols = document.getElementById("asset-type-management-grid").getElementsByClassName("k-grid-content")[0].querySelector("colgroup").childNodes;
            gridCols[colNr].width = $(this).outerWidth();
        }
    })
}
