import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

module.export = Vue.component('assetrequestmodal',
    {
        name: "assetrequestmodal",
        props: {
            assetRequest: Object,
            assetCount: Number,
            assetsShouldBeExcluded: Boolean,
            assetFilter: Object
        },
        components: {
            DatePicker
        },
        data: function () {
            return {
                alerts: [],
                requestTypes: ["Termination request", "Return request", "Extension request", "Buy Out request"],
                requestType: null,
                RecursiveError: false
            };
        },
        mounted: function () {
            this.requestType = this.requestTypes[0];
        },
        methods: {
            addAlert: function (event) {
                this.alerts = [];
                this.alerts.push(event);
            },
            clearAlert: function (index) {
                this.alerts.splice(index, 1);
            },
            send: function (e) {
                e.preventDefault();
                this.$validator.validateAll().then((result) => {
                    this.clearAlert();
                    if (result) {
                        $("#sendbutton").prop("disabled", true);
                        const vueObject = this;
                        if (!this.assetsShouldBeExcluded) {
                            this.sendRequestForAssets(vueObject, this.assetRequest.AssetsToTerminate.assets).then((response) => {
                                if (response.success) {
                                    vueObject.$emit('alert',
                                        {
                                            "message": "Your request is sent, you will be contacted.",
                                            "isSuccess": true,
                                            "showPopup": true
                                        });
                                    vueObject.close();
                                } else {
                                    vueObject.addAlert(
                                        {
                                            "message": `Could not send request: ${response.data}`,
                                            "isSuccess": false
                                        });
                                    $("#sendButton").removeAttr("disabled");
                                }
                            },
                                (error) => {
                                    vueObject.addAlert(
                                        {
                                            "message": 'Could not send request due to a server error',
                                            "isSuccess": false
                                        });
                                    $("#sendButton").removeAttr("disabled");
                                });
                        } else {
                            this.fetchPagedTerminationData().then((response) => {
                                this.sendRequestForAssetsFromResponseRecursively(vueObject, response);
                                if (!vueObject.RecursiveError) {
                                    vueObject.$emit('alert',
                                        {
                                            "message": "Your request is sent, you will be contacted.",
                                            "isSuccess": true,
                                            "showPopup": true
                                        });
                                    vueObject.close();
                                } else {
                                    vueObject.$emit('alert',
                                        {
                                            "message": "Could not send request.",
                                            "isSuccess": false,
                                            "showPopup": true
                                        });
                                    $("#sendButton").prop("disabled", false);
                                }
                            },
                                (error) => {
                                    vueObject.addAlert(
                                        {
                                            "message": 'Could not send request due to a server error',
                                            "isSuccess": false
                                        });
                                    $("#sendButton").prop("disabled", false);
                                });
                        }
                    } else {
                        this.addAlert(
                            {
                                "message": 'Some fields in the form are invalid',
                                "isSuccess": false
                            });
                        $("#sendButton").prop("disabled", false);
                    }
                });
            },
            sendRequestForAssets: function(vueObject, selectedAssets)
            {
                const selectedContractIds = selectedAssets.map(i => i.contractId);
                return new Promise((resolve, reject) => {
                    $.ajax({
                        type: 'POST',
                        url: '/AssetRequest/SendRequestForAssets',
                        data: JSON.stringify({
                            requestType: vueObject.requestType.replace("request", "Request").replaceAll(" ", ""),
                            requestDate: vueObject.assetRequest.RequestDate,
                            assets: selectedAssets,
                            contractId: selectedContractIds[0],
                            additionalInformation: vueObject.assetRequest.AdditionalInformation,
                            firstName: vueObject.assetRequest.FirstName,
                            lastName: vueObject.assetRequest.LastName,
                            email: vueObject.assetRequest.Email,
                            phoneNumer: vueObject.assetRequest.PhoneNumber

                        }),
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            fetchPagedTerminationData: function (pageToken) {
                return new Promise((resolve, reject) => { 
                    $.ajax({
                        type: 'POST',
                        url: '/LeasedAssets/QueryTerminationData',
                        data: JSON.stringify(({ 
                            token: pageToken,
                            limit: 250,
                            filter: this.assetFilter
                        })),
                        success: function (response) {
                            resolve(response);
                        },
                        error: function (error) {
                            reject(error);
                        },
                        contentType: "application/json",
                        dataType: 'json'
                    });
                });
            },
            sendRequestForAssetsFromResponseRecursively: function (vueObject, response) {
                if (response) {
                    const token = response.pageToken;
                    const selectedAssets = response.items.filter(a => !this.assetRequest.AssetsToTerminate.assets.some(a2 => a2.id === a.id)).map((a) => ({
                        id: a.id,
                        contractId: a.contractId,
                        status: a.status
                    }));
                    this.sendRequestForAssets(vueObject, selectedAssets).then((requestResponse) => {
                        if (token) {
                            this.fetchPagedTerminationData(token).then((response) => {
                                this.sendRequestForAssetsFromResponseRecursively(vueObject, response);
                            },
                            (error) => {
                                vueObject.RecursiveError = true;
                                vueObject.addAlert(
                                    {
                                        "message": 'Could not request termination due to a server error',
                                        "isSuccess": false
                                    });
                            });      
                        }      
                    },
                    (error) => {
                        vueObject.RecursiveError = true;
                        vueObject.addAlert(
                            {
                                "message": 'Could not request termination due to a server error',
                                "isSuccess": false
                            });
                    });
                }
            },
            escapeClose: function (e) {
                if (e.keyCode == 27) {
                    this.close();
                }
            },
            close: function () {
                this.clearAlert();
                this.$validator.reset();
                $("#sendButton").prop("disabled", false);
                this.requestType = this.requestTypes[0];
                this.assetRequest.RequestDate = '';
                this.assetRequest.AdditionalInformation = '';
                this.assetRequest.FirstName = '';
                this.assetRequest.LastName = '';
                this.assetRequest.Email = '';
                this.assetRequest.PhoneNumber = '';
                $('#asset-request-modal').modal('hide');
            }
        }
    });